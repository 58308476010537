<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="8">
          <el-row class="card-style">
            <el-card>
              <el-image :src="product.picUrl" min-width="30" height="20" />
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row class="card-style">
            <el-card>
              <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
                <h1>{{ product.title }}</h1>
                <span>已售 {{ product.monthSale }}</span>
                <h2 style="color: red">¥{{ product.price }}</h2>
              </el-row>
              <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
                <span>配送: {{ product.from }} 至 {{ to }} </span>
              </el-row>
              <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
                <div style="margin-top: 10px">
                  <span>分类: </span>
                  <el-radio-group v-model="radio1" size="medium" @change="onClick">
                    <el-radio style="margin-top: 5px" label="1" border>北京</el-radio>
                    <el-radio style="margin-top: 5px" label="2" border>上海</el-radio>
                    <el-radio style="margin-top: 5px" label="3" border>广州</el-radio>
                    <el-radio style="margin-top: 5px" label="4" border>深圳</el-radio>
                  </el-radio-group>
                </div>
              </el-row>
              <el-form style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
                <span>库存: {{ product.stock }}</span>
              </el-form>
              <el-form style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
                <el-input-number v-model="num" :min="1" :max="10" label="描述文字" @change="handleChange" />
              </el-form>
              <el-form style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
                <el-button round type="danger" @click="buy">立即购买</el-button>
                <el-button round type="danger" @click="addCart">加入购物车</el-button>
              </el-form>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import { addCart, getProduct } from '@/api/mall'

export default {
  name: 'Product',
  data() {
    return {
      product: null,
      num: 1,
      radio1: '上海',
      radio2: '上海',
      radio3: '上海',
      radio4: '上海',
      to: '成都'
    }
  },
  created() {
    const itemId = this.$route.query.id
    if (itemId === undefined) {
      this.$message('没有 item id')
      return
    } else if (itemId === '') {
      this.$message('item id 为空')
      return
    }

    this.getData(itemId)
    document.title = '商品页面'
  },
  methods: {
    getData(itemId) {
      getProduct(itemId).then(resp => {
        if (resp.code === 0) {
          this.product = resp.data
          document.title = this.product.title
        }
      })
    },
    handleChange(value) {
      console.log(value)
    },
    onClick(value) {
      console.log(value)
    },
    buy() {
      this.$router.push({
        path: '/mall/order/confirm',
        query: {
          productId: this.product.itemId
        }
      })
    },
    addCart() {
      console.log(this.product)
      const jsonData = {}
      jsonData.itemId = this.product.itemId
      jsonData.num = this.num
      addCart(jsonData).then(resp => {
        if (resp.code === 0) {
          this.$message('已添加到购物车')
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
}

.card-style {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
}
</style>
